<template>
  <div class="family-wapper">
    <div class="list-wapper">
      <div class="header main-shadow ">
        <back/>
        <h3> Dr.HK | 醫聊通 </h3>
      </div>
      <van-list
        v-model="loadingFam"
        :finished="finishedFam"
        finished-text="No more"
        error-text="請求失敗，點擊重新加載"
        @load="onLoadFamily"
      >
        <van-cell v-for="(item, i) in familyList" :key="i">
          <template v-slot:title>
            <div @click="handleFamItemClick(item)" class="van-cell__group">
              <div class="row">
                <div class=" text-align-left active-text">
                  <div class="avatar-icon">
                    <img  v-if="item.icon" :src="item.icon" alt="" style="width: 3rem; object-fit: cover; border-radius: 100%;">
                    <img  v-else src="../assets/avatar.png" alt="" style="width: 3rem; object-fit: cover; border-radius: 100%;">
                  </div>
                </div>
                <div class="flex1">
                  <div class="text-align-left">
                    <span class="text-align-left">{{item.profile.nickname}}</span>
                  </div>
                  <div class="row appointment-subtitle">
                    <span class="appointment-txt">{{formatPhone(item.profile.phone)}}</span>
                  </div>
                  <div class="row appointment-subtitle">
                    <span class="appointment-txt">{{item.profile.address}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
    <div class="btn-group">
      <van-button round type="primary" block @click="toProfile({})">添加家庭成員</van-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Back from '@/components/back.vue'
// import baseApi from '@/api/api-base'
import store from '@/store'
import { useRouter } from "vue-router";
import userApi from '@/api/api-user'
import mitt from "mitt"

export default {
  name: 'familyList',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const emitter = mitt()
    const loading = ref(false);
    const refreshing = ref(false);
    const familyList = ref([]);
    const show = ref(false);
    const fieldValue = ref('');
    const options = ref([]);
    // eslint-disable-next-line no-unused-vars
    emitter.on('saveFamily', _e => onLoadFamily() )
    // eslint-disable-next-line no-unused-vars
    const onFinish = ({ selectedOptions }) => {
      show.value = false;
      fieldValue.value = selectedOptions.map((option) => option.name).join('/');
    };

    const onLoadFamily = ()=> {
      userApi.getFamily().then(res => {
        console.log(res)
        familyList.value = res.users;
      })  
    }
   
    const toProfile = (family)=> {
      router.push({
        name: "Profile",
        params: {profile: family}
      });
    }

    const formatPhone = (phone) =>{
      return "+" + phone.replaceAll("_", " ");
    }
    
    const handleFamItemClick=(item)=>{
      store.commit('appointment/SET_FAMILY', item)
      router.back()
    }

    return {
      onFinish,
      onLoadFamily,
      toProfile,
      handleFamItemClick,
      formatPhone,
      store,
      emitter,
      refreshing,
      loading,
      options,
      familyList,
      show,
    }
  }
}
</script>
<style scoped>
.family-wapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 16px;
  box-sizing: border-box;
}
  
.list-wapper{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: #fff;
  flex: 1;
}

</style>