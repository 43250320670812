import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/home.vue";
import DoctorList from "@/views/doctor-list.vue";
import DoctorDetail from "@/views/doctor-detail.vue";
import NotFound from "@/views/no-found.vue";
import History from "@/views/history.vue";
import Login from "@/views/login.vue";
import Download from "@/views/download.vue";
import AddressEdit from "@/views/address-edit.vue";
import AddressList from "@/views/address-list.vue";
import FamilyList from "@/views/family-list.vue";
import Profile from "@/views/profile.vue";
import Statement from "@/views/statement.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/doctorList",
    name: "DoctorList",
    component: DoctorList,
  },
  {
    path: "/doctorDetail",
    name: "DoctorDetail",
    component: DoctorDetail,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/family-list",
    name: "FamilyList",
    component: FamilyList,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/address-list",
    name: "AddressList",
    component: AddressList,
  },
  {
    path: "/address-edit",
    name: "AddressEdit",
    component: AddressEdit,
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
  },
  {
    path: "/statement",
    name: "Statement",
    component: Statement,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;