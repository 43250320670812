import { getUserInfo, setUserInfo, removeUserInfo, getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
import baseApi from '@/api/api-base'
import userApi from '@/api/api-user'
import { Toast } from 'vant';


const state = {
  token: getToken(),
  userInfo: getUserInfo(),
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, data) => {
    state.userInfo = data
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { mylinkToken } = userInfo
    return new Promise((resolve, reject) => {
      baseApi.login({ mylink_token: mylinkToken }).then(response => {
        const { token_info } = response
        if (token_info) {
          if (token_info.heals_token) {
            commit('SET_TOKEN', token_info.heals_token)
            setToken(token_info.heals_token)
          }
          commit('SET_USERINFO', token_info)
          setUserInfo(token_info)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  loginBySelf({ commit }, data) {
    // const { mylinkToken } = userInfo
    return new Promise((resolve, reject) => {
      baseApi.loginBySelf(data).then(response => {
        const { token } = response
        if (token) {
          // if (user_info.heals_token) {
          commit('SET_TOKEN', token)
          setToken(token)
          // }
          // commit('SET_USERINFO', user_info)
          // setUserInfo(user_info)
          resolve()
        } else {
          const { error_messages } = response.msg.response.data
          Toast(error_messages[0]);
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  register({ commit }, userInfo) {
    const { mylink_uid, phone, password } = userInfo
    return new Promise((resolve, reject) => {
      baseApi.register({ mylink_uid: mylink_uid, phone: phone, password: password }).then(response => {
        const { token, user } = response
        commit('SET_TOKEN', token)
        setToken(token)
        setUserInfo(user)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  saveFamily(_, data) {
    return new Promise((resolve, reject) => {
      userApi.postFamilyAdd(data).then(response => {
        console.log('response:', response);
        const { user } = response
        if (user) {
          resolve(user)
        } else {
          const { error_messages } = response.msg.response.data
          Toast(error_messages[0]);
          reject()
        }

      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      baseApi.getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      baseApi.logout().then(() => {
        commit('SET_TOKEN', '')
        removeToken()
        commit('SET_USERINFO', '')
        removeUserInfo()
        // resetRouter()

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      commit('SET_USERINFO', '')
      removeUserInfo()
      resolve()
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
