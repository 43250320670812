<template>
  <h1> 啊，404 了，沒有找到你想要的頁面。</h1>
</template>

<script>
export default {
  name: 'no-found',
  props: {
    msg: String
  }
}
</script>
