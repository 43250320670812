import request from '@/utils/request'

export default {
  // 获取医生列表
  getDoctorList: function (params) {
    return request({
      url: `/doctor_clinic/search`,
      method: 'get',
      params
    })
  },
  // 获取历史
  getHistories: function (params) {
    return request({
      url: `/medical_histories`,
      method: 'get',
      params
    })
  },
  // 获取医生详情
  getDoctorDetail: function (params) {
    return request({
      url: `/my/favorite_doctor/status`,
      method: 'get',
      params
    })
  },
  // 获取地址列表
  getAddress: function (params) {
    return request({
      url: `/address_book/get`,
      method: 'get',
      params
    })
  },
  // 获取地址树
  getAddressTree: function (params) {
    return request({
      url: `/address_book/address_tree`,
      method: 'get',
      params
    })
  },
  // 增加家庭成员 
  postFamilyAdd: function (data) {
    return request({
      url: `/user/dependents`,
      method: 'post',
      data
    })
  },
  // 获取家庭地址 
  getFamily: function (params) {
    return request({
      url: `/user/dependents`,
      method: 'get',
      params
    })
  },
  // 获取筛选条件 
  getSpecialties: function (params) {
    return request({
      url: `/doctor/specialties`,
      method: 'get',
      params
    })
  },
  // 获取筛选条件 
  getDistricts: function (params) {
    return request({
      url: `/districts`,
      method: 'get',
      params
    })
  },
}
