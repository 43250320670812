import axios from 'axios'
// import { getToken } from '@/utils/auth'
import qs from 'qs'

// import store from '@/store'
import common from "@/utils/common"
import { getToken } from '../utils/auth';
// create an axios instance
const service = axios.create({
  baseURL: common.apiUrl, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  timeout: 33000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.method === 'post') {
      console.log(config.headers['Content-Type'])
      if (!config.headers['Content-Type'] || config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') >= 0) {
        config.data = qs.stringify(config.data)
      }
    }
    //  else if (config.method == 'get') {

    // }
    config.headers['Accept-Language'] = "zh-Hant"
    const token = getToken();
    if (token) {
      //   // let each request carry token
      //   // ['X-Token'] is a custom headers key
      //   // please modify it according to the actual situation
      config.headers['Authorization'] = "Bearer " + token
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code != 0) {
      return res
    } else if (res.code == 10003) {
      this.$router.push({
        path: `/`,
      })
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug

    return { code: 5000, msg: error }
  }
)

export default service
