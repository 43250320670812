<template>
  <div>
    <div>
      <div class="header main-shadow">
        <back/>
        <h3> Dr.HK | 醫聊通 </h3>
      </div>
      <div class="form-box">
        <van-form @submit="onSubmit">
            <van-cell-group inset>
              <div class="avatar-group">
                <div class="label">個人圖片{{isIos}}</div>
                <div class="flex1"></div>
                <div class="avatar-icon">
                  <img v-if="form.icon" :src="form.icon" alt="" style="width: 3rem; object-fit: cover">
                  <img v-else src="../assets/avatar.png" alt="" style="width: 3rem; object-fit: cover; border-radius: 100%;"/>
                </div>
              </div>
              <van-field
                  v-model.trim="form.eng_name"
                  label="英文全名"
                  name="eng_name"
                  placeholder="請輸入英文全名"
                />
              <van-field
                  v-model.trim="form.chi_name"
                  label="中文全名"
                  name="chi_name"
                  placeholder="請輸入中文全名"
                />
              <van-field
                  v-model.trim="form.nickname"
                  label="暱稱"
                  name="nickname"
                  placeholder="請輸入暱稱"
                />
              <div v-if="isIOS" class="input-group">
                <div class="label">生日{{isIos}}</div>
                <div class="flex1"></div>
                <input type="date" class="date-input" placeholder="請選擇生日"  :value="dob"  @change="onSelectDobIos">
                <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"/>
              </div> 
              <div v-else>
                <van-cell title="生日" :value="dob" @click="showCalendar = true" />
                  <van-popup :show="showCalendar" position="bottom">
                    <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="生日"
                      :min-date="minDate"
                      :max-date="maxDate"
                      @confirm="onSelectDob" 
                      @cancel="showCalendar = false"
                    />
                  </van-popup>
              </div>
              <!-- <van-field
                v-model="dob"
                is-link
                readonly
                name="calendar"
                label="生日"
                placeholder="請選擇生日"
                show-subtitle="true"
                @click="showCalendar = true"
              />
              <van-calendar :show="showCalendar" :min-date="minDate" :max-date="maxDate" color="#227CC8" @close="showCalendar = false" @confirm="onSelectDob" /> -->
              <van-field
                v-model="gender"
                is-link
                readonly
                name="sex"
                label="性別"
                placeholder="點擊選擇性別"
                @click="showGender = true"
              />
              <van-popup :show="showGender" position="bottom">
                <van-picker
                  :columns="genderList"
                  @confirm="onSelectGender"
                  @cancel="showGender = false"
                />
              </van-popup>
              <van-field
                  v-model.trim="form.id_num"
                  label="證件號碼"
                  name="id_num"
                  placeholder="請輸入證件號碼"
                />
              <van-field
                  v-model="form.telephone"
                  name="telephone"
                  label="電話號碼(+852)"
                  placeholder="請輸入聯絡電話"
                />
              <van-field
                  v-model.trim="form.email"
                  name="email"
                  label="電子郵箱"
                  placeholder="請輸入電子郵箱"
                />
            </van-cell-group>
          <div style="margin: 16px;margin-top: 32px;">
            <van-button round block type="primary" native-type="submit" >
              添加家庭成員
            </van-button>
          </div>
        </van-form>
      </div>

    </div>
  </div>
</template>

<script>
import { ref ,reactive, onMounted, } from 'vue';
import Back from '@/components/back.vue'
// import baseApi from '@/api/api-base'
import store from '@/store'
import { useRouter } from "vue-router";
import mitt from "mitt";
import { Toast } from 'vant';

export default {
  name: 'profile',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const emitter = mitt()
    const form = reactive({
      icon: '',
      nickname: '',
      eng_name: '',
      chi_name: '',
      id_num: '',
      email: '',
      telephone: '',
    });
    const currentDate = new Date(2021, 0, 17);
    const minDate = new Date('1979-1-1');
    const maxDate = new Date();
    const genderList = ['男', '女']
    const showCalendar = ref(false);
    const showGender = ref(false);
    const gender = ref('男');
    const dob = ref();
    const show = ref(false);
    const fieldValue = ref('');
    const cascaderValue = ref('');
    const isIOS = navigator.userAgent;
    // const isIOS = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || navigator.userAgent.match(/\/ios\//);
    const fieldNames = {
      text: 'name',
      value: 'id',
      children: 'childs',
    };
    // watch(form.telephone,(newVal, oldVal) =>  {
    //   console.log('newVal, oldVal');
    //   if(newVal)
    //   console.log(newVal, oldVal);
    // })
    const options = ref([]);
    const onSelectDobIos = (date) => {
      dob.value = new Date(date.currentTarget.value).format("yyyy-MM-dd");
      showCalendar.value = false;
    };
    const onSelectDob = (date) => {
      dob.value = new Date(date).format("yyyy-MM-dd");
      showCalendar.value = false;
    };
    const onSelectGender = (data) => {
      showGender.value = false;
      gender.value = data;
    };
    
    onMounted(() => {
      console.log('mounted in the composition api!')
      const profile = router.currentRoute.value.params.profile;
      console.log('profile', profile);
    })

    const onSubmit = (values) => {
      if(!values.nickname){
        Toast('請輸入暱稱');
        return;
      }
   
      if(!dob.value){
        Toast('請選擇生日');
        return;
      }

      var regId = /[A-Z][0-9]{6}\([0-9]|[A-Z]\)$/
      if(!regId.test(values.id_num)){
        Toast('請輸入正確的證件號碼');
        return;
      }

      var reg = /(852)?((2[1-9]|3[145679]|5[1234569]9[0-8])[0-9]{7}|6[0-9]{8})$/
      if(!reg.test('852' + values.telephone)){
        Toast('請輸入正確的電話號碼');
        return;
      }

      store.dispatch('user/saveFamily', {
        nickname: values.nickname,
        eng_name: values.eng_name,
        chi_name: values.chi_name,
        id_num: values.id_num,
        email: values.email,
        dob: dob.value,
        gender: gender.value == '男' ? 'M' : 'F',
        telephone: '852_' + values.telephone,
        // uid: store.getters.userInfo.mylink_uid,
      }).then(res => {
        console.log(res)
        router.back();
        emitter.emit('saveFamily')
      })
     
    };
   
    return {
      onSubmit,
      onSelectDob,
      onSelectDobIos,
      onSelectGender,
      currentDate,
      dob,
      isIOS,
      gender,
      minDate,
      maxDate,
      genderList,
      emitter,
      options,
      showCalendar,
      showGender,
      cascaderValue,
      fieldNames,
      form,
      fieldValue,
      show,
    }
  }
}
</script>
<style scoped>
  .login-top{
    width: 100%;
  }
  .form-box {
    /* margin-left: 1.8rem;
    margin-right: 1.8rem; */
    padding-bottom: 1.2rem;
  }
  .form-box .label{
    margin-top: 1.2rem;
    text-align: left;
  }
  .van-cell {
    background: transparent;
  }
  .input-group {
    height: 2.7rem;
    color: #227CC8;
    font-size: .8rem;
    /* background: rgba(34, 124, 200, 0.04); */
    /* border: 1px solid #227CC8; */
    /* box-shadow: 0px 7px 21px 0px rgba(70, 125, 153, 0.17); */
    border-radius: 39px;
    margin: 0 .8rem;
    margin-top: 0.6rem;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ebedf0;
    outline: none;
    border-radius: 0;
    display: flex;
  }
  .input-group .label{
    text-align: left;
  }
  .input-group center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :deep().van-field__control{
    text-align: right;
  }
  .avatar-group{
    display: flex;
    flex-direction: row;
    margin: 0 16px;
    border-bottom: 1px solid #ebedf0;
    padding-bottom: 4px;
  }
  .avatar-icon{
    margin-right: 0;
  }
  .input-group .label{
    flex: 1;
  }
  .form-box .label{
    margin-top: 0;
    line-height: 3rem;
    color: #227CC8;
    font-size: .8rem;
  }
  .form-icon {
    width: 1.2rem;
  }
  :deep().van-field__label{
    color: #227CC8;
    font-size: .8rem;
    width: 100px;
  }
  :deep().van-cell-group--inset{
    margin-left: 0;
    margin-right: 0;
    margin-top: 16px;
  }
  .date-input {
    border: 0;
    background: transparent;
    outline: none;
    /* outline-color: transparent; */
    width: 100px;
    text-align: right;
  }
  :deep().van-popup--center {
    width: 300px;
    height: 350px;
  }
  :deep().van-cell__title, .van-cell__value {
    text-align: left;
    color: #227CC8;
  }
</style>