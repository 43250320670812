import request from '@/utils/request'

export default {
  // 登录
  login: function (params) {
    return request({
      url: `/cmhk/mylink_token_info`,
      method: 'GET',
      params
    })
  },

  // 主动登录cmhk
  loginBySelf: function (data) {
    return request({
      url: `/user/auth`,
      method: 'post',
      data
    })
  },

  getCode: function (data) {
    return request({
      url: `/user/sms/request`,
      method: 'post',
      data
    })
  },
  register: function (data) {
    return request({
      url: `/user/register_from_mylink`,
      method: 'post',
      data
    })
  },
  preSaveAdd: function (data) {
    return request({
      url: `/cmhk/address/pre_reg`,
      method: 'post',
      data
    })
  },
  saveAdd: function (data) {
    return request({
      url: `/address_book/add`,
      method: 'post',
      data
    })
  },
  preVisit: function (data) {
    return request({
      url: `/cmhk/remote_visit/pre_reg`,
      method: 'post',
      data
    })
  },
  preAppointment: function (data) {
    return request({
      url: `/cmhk/appointment/pre_reg`,
      method: 'post',
      data
    })
  },
}
