import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const userInfoKey = 'userInfo'

const uidKey = 'uid'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getUid() {
  return Cookies.get(uidKey)
}

export function setUid(uid) {
  return Cookies.set(uidKey, uid)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  // if (localStorage.getItem(userInfoKey)) {
  //   // return JSON.parse(localStorage.getItem(userInfoKey))
  // }
  return undefined
}

export function setUserInfo(userInfo) {
  return localStorage.setItem(userInfoKey, JSON.stringify(userInfo))
}

export function removeUserInfo() {
  return localStorage.removeItem(userInfoKey)
}

export function removeAll() {
  return localStorage.removeAll()
}

