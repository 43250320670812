import baseApi from '@/api/api-base';
import { Toast } from 'vant';

const state = {
  address: {},
  family: {},
  appointmentType: 0,
}

const mutations = {
  SET_ADDRESS: (state, address) => {
    state.address = address
  },
  SET_FAMILY: (state, data) => {
    state.family = data
  },
  SET_APPOINTMENT_TYPE: (state, data) => {
    state.appointmentType = data
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  saveAddressPre({ commit }, data) {
    const { city, address, recipientName, streetAddr1, streetAddr2, recipientPhone, uid, } = data
    const adds = address.split("/")
    const area = adds[0];
    const region = adds[1];
    const district = adds[2];
    const params = {
      city: city,
      area: area,
      region: region,
      district: district,
      street_addr_1: streetAddr1,
      street_addr_2: streetAddr2,
      recipient_name: recipientName,
      recipient_phone: recipientPhone,
      is_default: 1,
      mylink_uid: uid
    }
    return new Promise((resolve, reject) => {
      baseApi.preSaveAdd(params).then(response => {
        // const {  } = response
        console.log('response:', response);
        // commit('SET_ADDRESS', params)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  saveAddress({ commit }, data) {
    console.log("data=====", data)
    const { city, address, recipientName, streetAddr1, streetAddr2, recipientPhone, } = data
    const adds = address.split("/")
    const area = adds[0];
    const region = adds[1];
    const district = adds[2];
    const params = {
      city: city,
      area: area,
      region: region,
      district: district,
      street_addr_1: streetAddr1,
      street_addr_2: streetAddr2,
      recipient_name: recipientName,
      recipient_phone: recipientPhone,
      is_default: 1
    }
    return new Promise((resolve, reject) => {
      baseApi.saveAdd(params).then(response => {
        // const {  } = response
        console.log('======data====', response);
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  queue({ commit }, data) {
    return new Promise((resolve, reject) => {
      baseApi.preVisit(data).then(response => {
        console.log('response:', response);
        const { success } = response
        if (success) {
          commit('SET_ADDRESS', data)
          resolve()
        } else {
          const { error_messages } = response.msg.response.data
          Toast(error_messages[0]);
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  appointment({ commit }, data) {
    return new Promise((resolve, reject) => {
      baseApi.preAppointment(data).then(response => {
        console.log('response:', response);
        const { success } = response

        if (success) {
          commit('SET_ADDRESS', data)
          resolve()
        } else {
          const { error_messages } = response.msg.response.data
          Toast(error_messages[0]);
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
