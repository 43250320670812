<template>
  <div class="container">
    <div class="header main-shadow ">
      <back/>
      <h3> Dr.HK | 醫聊通 </h3>
      <div v-if="isShowHis" @click="toHistory" class="icon-box">就診記錄</div>
    </div>
    <van-dropdown-menu>
      <van-dropdown-item v-model="valueSpec" :options="specialties" @change="getDoctorList"/>
      <van-dropdown-item v-model="valueDist" :options="districts" @change="getDoctorList"/>
      <van-dropdown-item v-model="valueSer" :options="services" @change="getDoctorList"/>
    </van-dropdown-menu>
    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="No more"
        error-text="請求失敗，點擊重新加載"
        @load="onLoad"
      >
        <van-cell v-for="(item, i) in doctorList" :key="i">
          <template v-slot:title>
            <div @click="handleItemClick(item)" class="van-cell__group">
              <div class="row">
                <img src="../assets/medicine_box.png" alt="" class="box-icon">
                <div class="flex1 text-align-left active-text">
                  <span class="flex1 appointment-title">{{item.doctor.name}}</span>
                </div>
                <!-- <img src="../assets/like.png" alt="" class="like-icon"> -->
              </div>
              <div class="ml30 text-align-left">
                <span class="text-align-left">{{item.doctor.specialty.name}}</span>
              </div>
              <div class="row ml30 borderB appointment-subtitle">
                <span class="appointment-txt">{{item.clinic.name}}</span>
              </div>
              
              <div class="row ml30 appointment-mark">
                <div class="flex1"></div>
                <span class="appointment-txt">排隊人數:<label class="active-text">{{item.position}}</label></span>
              </div>

            </div>
          </template>
        </van-cell>
      </van-list>
    <!-- </van-pull-refresh> -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import userApi from '@/api/api-user'
import Back from '@/components/back.vue'
import { useRouter } from "vue-router";
import store from '../store';
import {createNamespacedHelpers} from 'vuex'
const {mapState} = createNamespacedHelpers('user');
export default {
  name: "doctor-list",
  components:{
    Back,
  },
  computed: {
      ...mapState({
          userInfo: state => state.userInfo, //指定模块后，这里默认就是获取user下面的state了
      })
  },
  setup() {
    const router = useRouter();
    let doctorList = ref([]);
    const valueSpec = ref(0);
    const valueDist = ref(0);
    const valueSer = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const isShowHis = ref(store.getters.userInfo&&store.getters.userInfo.mylink_uid);
    let specialties = ref([]);
    let districts = ref([]);
    let services = [
      {text: '服務', value: 0},
      {text: '預約', value: 1},
      {text: '排隊', value: 2},
      {text: '線上診症', value: 3}
    ];
    onMounted(() => {
      console.log('mounted in the composition api!')
      let type = router.currentRoute.value.query.type;
      if(type == 'tel'){
        valueSer.value = 3;
        getData();
      }
    })

    const onLoad = () => {
      // 异步更新数据
      getData();
      getDoctorList();
    };

    const getDoctorList=()=>{
      userApi.getDoctorList({
        specialty_id: valueSpec.value != 0 ? valueSpec.value : null,
        district_id: valueDist.value != 0 ? valueDist.value : null,
        isAppointment: valueSer.value == 1 ? 1 : null,
        isRemote: valueSer.value == 2 ? 1 : null,
        hasVideoVisit: valueSer.value == 3 ? 1: null
        }).then(res => {
        doctorList.value = res.doctor_clinics
        loading.value = false;
        refreshing.value = false;
      })
    }
    const getData=()=>{
    //   specialties = [
    //     {text: '全科', value: 0}
    //   ]
    //   districts = [
    //   {text: '地區', value: 0}
    // ]
      userApi.getSpecialties().then(res => {
        specialties.value = []
        specialties.value.push({text: '全科', value: 0})
        res.specialties.forEach(specialty => {
          specialties.value.push({text: specialty.name, value: specialty.id})
        });
      })
      userApi.getDistricts().then(res => {
        districts.value = []
        districts.value.push({text: '地區', value: 0})
        res.districts.forEach(district => {
          districts.value.push({text: district.name, value: district.id})
        });
      })
    };
    const handleItemClick=(item)=>{
        router.push({name: 'DoctorDetail', query: {'doctorId': item.doctor.id, 'clinicId': item.clinic_id}})
      // if(store.getters.userInfo.heals_uid){
      //   router.push({name: 'Download'})
      // }  else {
      //   router.push({name: 'Login'})
      // }
    }
    const onRefresh=()=>{
      getDoctorList();
    }
    const toHistory=()=>{
      router.push({
        name: "Login",
        params: {type: 'login'}
      });
    }
 
    
    

    return {
      doctorList,
      onLoad,
      getData,
      getDoctorList,
      toHistory,
      onRefresh,
      handleItemClick,
      isShowHis,
      loading,
      store,
      finished,
      valueSpec,
      valueDist,
      valueSer,
      specialties,
      districts,
      services,
      refreshing,
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  position: relative;
}
.icon-box {
  position: absolute;
  top: 0;
  right: 0;
  height: 3.5rem;
  line-height: 4rem;
  padding-right: 1.2rem;
  background: url(../assets/health_passport.png) no-repeat;
  background-size: 1.4rem;
  background-position-y: 1rem;
}
.van-list {
  flex: 1;
  height: calc(100% - 1rem);
  overflow-y: scroll;
}
.van-dropdown-menu {
  margin-bottom: .8rem;
}
.van-cell:after{
  border-bottom: none;
}
.van-cell__group .row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}
.van-cell__group .box-icon{
  width: 1.1rem;
  margin-right: .4rem;
}
.van-cell__group .like-icon{
  width: 1.1rem;
  margin-right: .4rem;
}
.appointment-title {
  font-size: .9rem;
  font-weight: bold;
}
.appointment-subtitle {
  padding-bottom: .7rem;
  margin-top: .8rem;
}
.appointment-mark {
  margin-top: .2rem;
}
.appointment-mark label{
  font-size: 1rem;
  font-weight: bold;
  margin-left: .25rem;
}
</style>
