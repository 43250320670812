<template>
  <div id="home">
    <div class="header main-shadow">
      <h3> Dr.HK | 醫聊通 </h3>
    </div>
    <img src="../assets/kv-banner-tc.png" alt="" class="banner-top">
    <div class="flex card-box">
      <div class="card-left" @click="toDoctorList('tel')">
        <p class="video-consultation">線上診症</p>
        <img src="../assets/video_consultation.png" alt="" class="card-1">
      </div>
      <div class="card-middle"></div>
      <div v-if="!(store.getters.userInfo&&store.getters.userInfo.mylink_uid)" class="card-left" @click="toDoctorList('all')">
        <p class="video-consultation">查看醫生{{userInfo}}</p>
        <img src="../assets/find_doctor.png" alt="" class="card-1">
      </div>
      <div v-else class="card-right">
        <div class="short-card-box" @click="toDoctorList('all')" >
          <p class="card-top">查看醫生</p>
          <img src="../assets/find_doctor.png" alt="" class="card-2">
        </div>
        <div v-if="userInfo" class="short-card-box" @click="toHistory()">
          <p class="card-bottom">就診記錄</p>
          <img src="../assets/my_booking.png" alt="" class="card-2">
        </div>
      </div>
    </div>
    <div class="flex1 card-box"></div>
    <!-- <img src="../assets/bottom.png" alt="" class="bottom-bg"> -->
     <div class="download-footer">
        <ul class="download-ul">
          <li>醫療網絡突破界限*</li>
          <li>涵蓋西醫、中醫及多個專科</li>
          <li>線上預約多渠道看診</li>
          <li>隨時手機取籌，不再等待 </li>
          <li>即日送藥上門</li>
        </ul>
      </div>
  </div>
</template>

<script>
import { ref,onMounted  } from 'vue';
import userApi from '@/api/api-user'
import store from '@/store'
import { Dialog } from 'vant'
import { useRouter } from "vue-router";
// import { computed } from '@vue/reactivity';
import {createNamespacedHelpers} from 'vuex'
const {mapState} = createNamespacedHelpers('user');
// import { mapState } from 'vuex';
export default {
  name: 'home',
  computed: {
      ...mapState({
          userInfo: state => state.userInfo, //指定模块后，这里默认就是获取user下面的state了
      })
  },
  setup() {
    const router = useRouter();
    const historyList = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    onMounted(() => {
      console.log('mounted in the composition api!')
      onLoad();
    })
    const onLoad = () => {
      // 异步更新数据
      getData();
      postLogin();
    };
    const getData  = ()=> {
      console.log('historyList')
      userApi.getHistories().then(res => {
        historyList.value = res.medical_histories;
        console.log(historyList)
        loading.value = false;
        refreshing.value = false;
      })  
    }
    const goBack =()=>{
      router.back();
    }
    const toDoctorList=(type)=>{
        Dialog.confirm({
        title: '重要提示',
        theme: 'round-button',
        confirmButtonText: '確認',
        message: '線上診症不適用於急診，如遇緊急情況，請前往就近的診所或醫院作進一步檢查或治療。',
        'cancel-button-color': 'rgba(0, 0, 0, .2)',
        'confirm-button-color': '#227CC8',
        })
        .then(() => {
          router.push({
            name: "DoctorList",
            query: {type: type}
          });
        })
        .catch(() => {
          // on cancel
        });
      
    }
    const toHistory=()=>{
      router.push({
        name: "Login",
        params: {type: 'login'}
      });
    }
    const postLogin=()=>{
      let key = router.currentRoute.value.query.key
      console.log(key);
      store.dispatch('user/login', {
        mylinkToken: key,
      })
    }

    return {
      router,
      postLogin,
      toDoctorList,
      toHistory,
      onLoad,
      goBack,
      store,
      finished,
      loading,
      refreshing
    }
  }
}
</script>

<style scoped>
  #home{
    background: linear-gradient(#fff, #97dbf5);
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .banner-top{
    width: 100%;
  }
  .card-1{
    height: 83px;
  }
  .video-consultation{
    font-size: 20px;
    font-family: Comfortaa;
    font-weight: bold;
    color: #227CC8;
    line-height: 20px;
    text-align: left;
    margin: 12px 16px;
  }
  .card-box{
    /* background: #2aa0ef; */
    padding: 12px 32px;
  }
  .card-left{
    width: calc(100% - 96px);
    height: 172px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(15,63,103,0.4400);
    border-radius: 12px;
  }
  .card-middle{
    width: 32px;
  }
  .card-right{
    width: calc(100% - 96px);
  }
  .short-card-box{
    display: flex;
    width: 160px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(15,63,103,0.4400);
    border-radius: 12px;
  }
  .short-card-box:first-child{
    margin-bottom: 12px;
  }
  .card-2{
    width: 60px;
    height: 60px;
    margin-top: 10px;
    margin-right: 10px;
  }
  .card-top{
    width: 108px;
    height: 67px;
    font-size: 16px;
    font-family: Comfortaa;
    font-weight: bold;
    color: #227CC8;
    line-height: 20px;
  }
  .card-bottom{
    width: 108px;
    height: 67px;
    font-size: 16px;
    font-family: Comfortaa;
    font-weight: bold;
    color: #227CC8;
    line-height: 20px;
  }
  .download-footer{
    background: url('../assets/bottom-bg-1.png') no-repeat;
    background-size: 100% 120px;
    height: 120px;
    color: #fff;
    text-align: left;
  }
  .download-footer .download-ul{
    padding-top: 2.6rem;
  }
  .download-footer .download-ul li{
    font-size: .8rem;
    font-weight: bold;
    width: 50%;
    float:left;
    box-sizing: border-box;
    padding-left: 1.6rem;
    line-height: 1.2rem;
  }
  .bottom-bg{
    width: 100%;
    height: 100px;
  }
</style>