<template>
  <div>
    <div>
      <back/>
      <div class="">
        <img src="../assets/login_top_bg.png" alt="" class="login-top">
      </div>
      <div class="form-box main-shadow">
        <van-form  @submit="onSubmit">
          <van-cell-group inset>
            <div class="flex input-group">
              <label>+852</label>
              <div class="flex1">
               <van-field
                  v-model="form.phone"
                  name="phone"
                  disabled="true"
                  placeholder="請輸入電話號碼"
                />

              </div>
            </div>
            </van-cell-group>
            <van-cell-group inset>
              <div class="flex input-group">
                <img src="../assets/change_password.png" alt="" class="form-icon">
                <van-field
                  v-model.trim="form.password"
                  type="password"
                  name="password"
                  placeholder="請輸入密碼"
                />
              </div>
            </van-cell-group>
            <van-cell-group v-if="type == 'register'" inset>
              <div class="flex input-group">
                <img src="../assets/change_password.png" alt="" class="form-icon">
                <van-field
                  v-model.trim="form.repassword"
                  type="password"
                  name="repassword"
                  placeholder="請再次輸入密碼"
                />
              </div>
            </van-cell-group>
           
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              {{type=='register'? '註冊' : '登錄'}}
            </van-button>
          </div>
        </van-form>
      </div>
      <div class="teatment">
        <van-checkbox v-model="checked" shape="square">
          請閱讀並同意 
          <a @click="toStatement('https://heals-nc1.s3.ap-east-1.amazonaws.com/cmhk-app/privacy-policytc.html')">隱私條款</a> 與
          <a @click="toStatement('https://heals-nc1.s3.ap-east-1.amazonaws.com/cmhk-app/tctc.html')">服務條款</a>
        </van-checkbox>
      
      </div>
    </div>
  </div>
</template>

<script>
import { ref ,reactive, onMounted } from 'vue';
import Back from '@/components/back.vue'
// import baseApi from '@/api/api-base'
import store from '@/store'
import { useRouter } from "vue-router";
import { Toast } from 'vant';

export default {
  name: 'login',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const form = reactive({
      // phone: '84901204',
      phone: store.getters.userInfo&&store.getters.userInfo.phone?store.getters.userInfo.phone.split("_")[1]:'',
      verification: '',
      password: '',
      // password: 'Asdf1234',
      repassword: '',
    });
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const sending = ref(false);
    const resend = ref(false);
    const time = ref(0);
    const checked = ref(false);
    const type = ref('register');
    
    // const timer = setInterval(() => {
    //   if(time.value <= 0) {
    //     resend.value = true;
    //     clearInterval(timer);
    //   }
    //   time.value = time.value --;
    // }, 1000)
    
    // const getCode = ()=> {
    //   console.log(form.phone)
      
    //   if(time.value>0) return;
    //   baseApi.getCode({phone: '852_' + form.phone, }).then(res => {
    //     console.log(res)
    //     sending.value = true;
    //     time.value = 59;
    //   })  
    // }
    onMounted(() => {
      type.value = router.currentRoute.value.params.type;
     
      console.log('mounted in the composition api!', type)
    })

    const toStatement = (url) => {
      router.push({
        name: "Statement",
        params: {statementUrl: url}
      });
    }

    const onSubmit = (values) => {
      console.log('submit', values);
      if(!checked.value){
        Toast('請同意服務條款和私隱政策。');
        return;
      }
      var reg = /(852)?((2[1-9]|3[145679]|5[1234569]9[0-8])[0-9]{7}|6[0-9]{8})$/
      if(!reg.test('852'+ values.phone) && type.value == 'login'){
        Toast('請輸入正確的電話號碼');
        return;
      }

      if(values.password!=values.repassword && type.value == 'register') {
         Toast('兩次密碼不一致');
        return;
      }
      if(values.password.length < 8 || values.password.length > 16) {
         Toast('請輸入8-16位密碼');
        return;
      }
      const regPass = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,12}/
      if(!regPass.test(values.password) && type.value == 'register'){
         Toast('密碼請使用數字和大小寫字母的組合');
        return;
      }
      if (type.value=='login') {
        store.dispatch('user/loginBySelf', {
          phone: "852_" + values.phone,
          // mylink_uid: store.getters.userInfo.mylink_uid,
          password: values.password,
          source: 'CMHK'
        }).then(res => {
          console.log(res)
          finished.value = true;
          toHistory();
        })
      } else {
        store.dispatch('user/register', {
          phone: "852_" + store.getters.userInfo.phone,
          mylink_uid: store.getters.userInfo.mylink_uid,
          password: values.password
        }).then(res => {
          console.log(res)
          finished.value = true;
          toDownload();
        })
      }
    };
    const toHistory=()=>{
      console.log(router)
      router.replace({
        name: 'History'
      });
    }
    const toDownload=()=>{
      router.push({
        name: "Download",
      });
    }
    return {
      onSubmit,
      toDownload,
      toStatement,
      toHistory,
      form,
      finished,
      loading,
      refreshing,
      sending,
      resend,
      time,
      checked,
    }
  }
}
</script>
<style scoped>
  .login-top{
    width: 100%;
  }
  .form-box {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    padding-bottom: 1.2rem;
    padding-left: .4rem;
    padding-right: .4rem;
  }
  .van-cell {
    background: transparent;
  }
  .input-group {
    height: 2.7rem;
    background: rgba(34, 124, 200, 0.04);
    border: 1px solid #227CC8;
    box-shadow: 0px 7px 21px 0px rgba(70, 125, 153, 0.17);
    border-radius: 39px;
    padding: 0 .8rem;
    margin-top: 1.2rem;
  }
  .form-icon {
    width: 1.2rem;
  }
  .teatment{
    margin-top: .8rem;
    font-size: .8rem;
    display: flex;
    justify-content: center;
  }
  .teatment a{
    outline: none;
    color: #227CC8;
  }
  .teatment a:hover{
    outline: none;
    color: #227CC8;
  }
  :root {
    --van-checkbox-size: 14px;
}
</style>