<template>
  <div class="family-wapper">
    <div class="list-wapper">
      <div class="header main-shadow ">
        <back/>
        <h3> Dr.HK | 醫聊通 </h3>
      </div>
      <div class = "flex1">
        <van-list
          v-model="loadingAdd"
          :finished="finishedAdd"
          finished-text="No more"
          error-text="請求失敗，點擊重新加載"
          @load="onLoadAddress"
        >
          <van-cell v-for="(item, i) in addressList" :key="i">
            <template v-slot:title>
              <div @click="handleAddItemClick(item)" class="van-cell__group">
                <div class="row">
                  <div class="flex1 text-align-left active-text">
                    <span class="flex1 appointment-title">{{item.recipient_name}}</span>
                  </div>
                </div>
                <div class="text-align-left">
                  <span class="text-align-left">{{item.recipient_phone}}</span>
                </div>
                <div class="row appointment-subtitle">
                  <span class="appointment-txt">{{item.streetAddr1}}{{item.district}}{{item.region}}{{item.area}}{{item.city}}</span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>
    <div class="btn-group">
      <van-button round type="primary" block @click="toAddressEdit({})">添加地址</van-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Back from '@/components/back.vue'
// import baseApi from '@/api/api-base'
import store from '@/store'
import { useRouter } from "vue-router";
import userApi from '@/api/api-user'
import { getToken } from '../utils/auth';
import mitt from "mitt"

export default {
  name: 'addressList',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const emitter = mitt()
    const loading = ref(false);
    const refreshing = ref(false);
    const addressList = ref([]);
    const show = ref(false);
    const fieldValue = ref('');
    const options = ref([]);
    // eslint-disable-next-line no-unused-vars
    emitter.on('save', _e => onLoadAddress() )
    // eslint-disable-next-line no-unused-vars
    const onFinish = ({ selectedOptions }) => {
      show.value = false;
      fieldValue.value = selectedOptions.map((option) => option.name).join('/');
    };

    const onLoadAddress  = ()=> {
      userApi.getAddress().then(res => {
        console.log(res)
        addressList.value = res.address_books
      })  
    }

    const toProfile = (family)=> {
      router.push({
        name: "AddressEdit",
        params: {profile: family}
      });
    }
    const showAddress=()=>{
      const token = getToken();
      if(!token){
        router.push({
          name: "AddressEdit",
      });
      }
    }
   const toAddressEdit =()=>{
      router.push({
        name: "AddressEdit",
      });
    }
    const handleAddItemClick=(item)=>{
      store.commit('appointment/SET_ADDRESS', item)
      router.back()
    }

    return {
      onFinish,
      onLoadAddress,
      toProfile,
      handleAddItemClick,
      toAddressEdit,
      showAddress,
      store,
      emitter,
      refreshing,
      loading,
      options,
      addressList,
      show,
    }
  }
}
</script>
<style scoped>
.family-wapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 16px;
  box-sizing: border-box;
}
  
.list-wapper{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: #fff;
  flex: 1;
}
</style>