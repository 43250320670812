<template>
  <div id="app">
    <router-view  v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
    <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fff;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
.inline-block {
  display: inline-block;
}
.mt8{
  margin-top: .4rem;
}
.mt16{
  margin-top: .8rem;
}
.ml30 {
  margin-left: 1.5rem;
}
.mt30 {
  margin-top: 1.5rem;
}
.mb30 {
  margin-bottom: .75rem;
}
.pb30 {
  padding-bottom: .75rem;
}
.borderB {
  border-bottom: 1px solid #e6e6e6;
}
.flex-left {
  flex-direction: row;
  justify-content: flex-start;
}
.flex-right {
  flex-direction: row;
  justify-content: flex-end;
}
.text-align-left {
  text-align: left;
}
.active-text {
  color: #227CC8;
}
.main-shadow {
  box-shadow: 0px .35rem .4rem 0px rgba(70, 125, 153, 0.17);
}
.header {
  position: relative;
  height: 4rem;
  background: url(./assets/header_bg.png) no-repeat center;
  background-size: 100% 4rem;
  color: #fff;
}
.back-box {
  position: absolute;
}
.header h3 {
  margin: 0;
  line-height: 4rem;
}
.back-icon {
  height: 1.5rem;
  padding-left: .8rem;
  padding-top: 1.25rem;
}

.van-cell__group {
  display: flex;
  font-size: .8rem;
  color: #333;
  flex-direction: column;
  border-bottom: none;
  margin: 0 .4rem 0 .4rem; 
  background: #FFFFFF;
  box-shadow: 0px .35rem .4rem 0px rgba(70, 125, 153, 0.17);
  border-radius: 12px;
  padding: .8rem;
}
.van-dialog__header{
  color: #227CC8;
  font-size: 1.1rem;
  font-weight: bold;
}
.van-dialog--round-button .van-dialog__message{
  color: #07333C;
  font-size: .9rem;
}

.avatar-icon{
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid #eee;
  margin-right: .8rem
}
.btn-group{
  margin: 0 16px;
}
</style>
