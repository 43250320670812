<template>
  <div>
    <div>
      <div class="header main-shadow">
        <back/>
        <h3> Dr.HK | 醫聊通 </h3>
      </div>
      <div class="form-box">
        <van-form @submit="onSubmit">
            <van-cell-group inset>
              <div class="label">收件人</div>
              <div class="flex input-group">
                <van-field
                  v-model.trim="form.recipientName"
                  name="recipientName"
                  placeholder="請輸入收件人"
                />
              </div>
              <div class="label">聯絡電話</div>
              <div class="flex input-group">
                <div>+852</div>
                <van-field
                  v-model.trim="form.phone"
                  name="phone"
                  placeholder="請輸入聯絡電話"
                />
              </div>
              <div class="label">街道地址1</div>
              <div class="flex input-group">
                <van-field
                  v-model.trim="form.address1"
                  name="address1"
                  placeholder="請輸入街道地址1"
                />
              </div>
              <div class="label">街道地址2</div>
              <div class="flex input-group">
                <van-field
                  v-model.trim="form.address2"
                  name="address2"
                  placeholder="請輸入街道地址2"
                />
              </div>
              <div class="label">區域</div>
              <div class="flex input-group">
                <van-field
                  v-model="fieldValue"
                  is-link
                  readonly
                  placeholder="請请選擇所在地區"
                  @click="show = true"
                />
                <van-popup :show="show" round position="bottom">
                  <van-cascader
                    v-model="cascaderValue"
                    title="请選擇所在地區"
                    :options="options"
                    :field-names="fieldNames"
                    @close="show = false"
                    @finish="onFinish"
                  />
                </van-popup>
              </div>
              <!-- <div class="flex input-group">
                <van-field
                  v-model.trim="form.password"
                  name="password"
                  placeholder="請輸入區域"
                />
              </div>
              <div class="label">分區</div>
              <div class="flex input-group">
                <van-field
                  v-model.trim="form.password"
                  name="password"
                  placeholder="請輸入分區"
                />
              </div>
              <div class="label">地區</div>
              <div class="flex input-group">
                <van-field
                  v-model.trim="form.password"
                  name="password"
                  placeholder="請輸入地區"
                />
              </div> -->
            </van-cell-group>
          <div style="margin: 16px;margin-top: 32px;">
            <van-button round block type="primary" native-type="submit" >
              保存
            </van-button>
          </div>
        </van-form>
      </div>

    </div>
  </div>
</template>

<script>
import { ref ,reactive, onMounted  } from 'vue';
import Back from '@/components/back.vue'
// import baseApi from '@/api/api-base'
import store from '@/store'
import { useRouter } from "vue-router";
import userApi from '@/api/api-user'
import mitt from "mitt"
import { getToken } from '../utils/auth';

export default {
  name: 'addressEdit',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const emitter = mitt()
    const form = reactive({
      recipientName: '',
      phone: '',
      address1: '',
      address2: '',
    });
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const show = ref(false);
    const fieldValue = ref('');
    const cascaderValue = ref('');
    const fieldNames = {
      text: 'name',
      value: 'id',
      children: 'childs',
    };
    const options = ref([]);
    const onFinish = ({ selectedOptions }) => {
      show.value = false;
      fieldValue.value = selectedOptions.map((option) => option.name).join('/');
    };

    
    onMounted(() => {
      console.log('mounted in the composition api!')
      getData();
    })
    const getData = ()=> {
     userApi.getAddressTree({
        }).then(res => {
        loading.value = false;
        refreshing.value = false;
        let data = res.address_trees;
        setList(data);
        options.value = data.filter((item)=> item.name=='香港')[0].childs; // 从第二级开始
      })
    }

    const setList=(data)=>{
      data.forEach(item => {
        if(item.childs.length==0){
          item.childs = null;
        }else{
          setList(item.childs);
        }
      }); 
    }

    const onSubmit = (values) => {
     const token = getToken();
      if(!token){
        store.dispatch('appointment/saveAddressPre', {
          city: 'Hong Kong',
          address: fieldValue.value,
          streetAddr1: values.address1,
          streetAddr2: values.address2,
          recipientName: values.recipientName,
          recipientPhone: "852_" + values.phone,
          uid: store.getters.userInfo.mylink_uid,
        }).then(res => {
          console.log(res)
          finished.value = true;
          router.back();

        })
      }else{
        store.dispatch('appointment/saveAddress', {
          city: 'Hong Kong',
          address: fieldValue.value,
          streetAddr1: values.address1,
          streetAddr2: values.address2,
          recipientName: values.recipientName,
          recipientPhone: "852_" + values.phone,
        }).then(res => {
          console.log(res)
          finished.value = true;
          router.back();
          emitter.emit('save')
        })
      }
     
    };
   
    return {
      onSubmit,
      onFinish,
      emitter,
      options,
      cascaderValue,
      fieldNames,
      form,
      fieldValue,
      show,
    }
  }
}
</script>
<style scoped>
  .login-top{
    width: 100%;
  }
  .form-box {
    /* margin-left: 1.8rem;
    margin-right: 1.8rem; */
    padding-bottom: 1.2rem;
    padding-left: .4rem;
    padding-right: .4rem;
  }
  .form-box .label{
    margin-top: 1.2rem;
    text-align: left;
  }
  .van-cell {
    background: transparent;
  }
  .input-group {
    height: 2.7rem;
    background: rgba(34, 124, 200, 0.04);
    border: 1px solid #227CC8;
    box-shadow: 0px 7px 21px 0px rgba(70, 125, 153, 0.17);
    border-radius: 39px;
    padding: 0 .8rem;
    margin-top: 0.6rem;
  }
  .form-icon {
    width: 1.2rem;
  }
</style>