<template>
  <div @click="goBack" class="back-box"><img src="../assets/back_icon.png" alt="" class="back-icon"></div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    background: {
      type: String
    }
  },
  setup() {
    const router = useRouter();
    const goBack =()=>{
      router.back();
    }
    return {
      goBack,
      router,
    }
  }
})
</script>

<style scoped>
.back-box {
  position: absolute;
}
</style>
