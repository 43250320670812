<template>
  <div id="detail">
    <div class="top-wapper">
      <img src="../assets/doctor_bg.png" alt="" class="doctor-bg">
      <div class="top-box">
        <div style="z-index: 10;">
          <back/>
        </div>
        <img src="../assets/doctor_default_avatar.png" alt="" class="avatar">
        <div class="mt8" v-if="doctorModel.doctor">{{doctorModel.doctor.name}}</div>
       <div class="mt8" v-if="doctorModel.doctor"> {{doctorModel.doctor.specialty.name}}</div>
       <div v-if="doctorModel.clinic" class="info-box">
          <div class="flex mt8">
            <img src="../assets/clinic_white_icon.png" alt="" class="info-icon">
            <div class="info-text flex1">{{doctorModel.clinic.name}}</div>
          </div>
          <div class="flex mt8">
            <img src="../assets/phone_white_icon.png" alt="" class="info-icon">
            <div class="info-text flex1">{{doctorModel.clinic.phone_one}}</div>
          </div>
          <div class="flex mt8">
            <img src="../assets/address_white_icon.png" alt="" class="info-icon">
            <div class="info-text flex1">{{doctorModel.clinic.address_one}}</div>
          </div>
       </div>
      </div>
    </div>
    <van-form  @submit="onSubmit">
      <div class="appointment-wapper">
        <div class="wait-box flex">
          <div class="flex1 wait-title">前面等待人數：</div>
          <div>{{doctorModel.position}}</div>
        </div>
        <div class="wait-box flex" v-if="doctorModel.appointment_enabled == 1">
          <div class="flex1 wait-title">手機號碼(+852)：</div>
          <van-cell-group inset>
            <van-field
                v-model="form.phone"
                name="phone"
                disabled="true"
                placeholder="請輸入電話號碼"
              />
          </van-cell-group>
        </div>
        <div>
          <div class="flex select-wapper">
            <div class="select-item flex" :class="{'active': store.getters.appointmentType == 0}" @click="handleItem(0)">
              <img v-if="doctorModel.appointment_enabled == 0" :src="store.getters.appointmentType==0 ? require('../assets/address_white_icon.png'):require('../assets/address.png')" class="select-icon">
              <div class="select-title">{{ doctorModel.appointment_enabled == 0 ? '診所就診': '最早時段'}}</div>
            </div>
            <div class="flex1"></div>
            <div class="select-item flex" :class="{'active': store.getters.appointmentType == 1}"  @click="handleItem(1)">
              <img v-if="doctorModel.appointment_enabled == 0"  :src="store.getters.appointmentType==1 ? require('../assets/video_icon.png'):require('../assets/video_active_icon.png')" alt="" class="select-icon">
              <div class="select-title">{{ doctorModel.appointment_enabled == 0 ? '線上診症': '期望日期'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="doctorModel.appointment_enabled == 0">
        <div v-if="store.getters.appointmentType==1" class="select-group-normal flex" @click="showAddress()">
          <div class="flex1 select-title">派送地址</div>
          <div>{{!store.getters.address.recipient_name? '選擇': store.getters.address.recipient_name}} <img src="../assets/view_all.png" alt="" class="icon-arrow"></div>
        </div>
        <div class="select-group-normal flex"  @click="showFamily()">
          <div class="flex1 select-title">為家庭成員預約</div>
          <div>{{!store.getters.family.nickname? '選擇':store.getters.family.nickname}} <img src="../assets/view_all.png" alt="" class="icon-arrow"></div>
        </div>
        <div class="input-group flex">
          <div class="flex1 input-label">手機號碼(+852)</div>
            <van-cell-group inset>
              <van-field
                  v-model="form.phone"
                  name="phone"
                  disabled="true"
                  placeholder="請輸入電話號碼"
                />
            </van-cell-group>
        </div>
       
      </div>
      <div v-if="doctorModel.appointment_enabled == 1 && store.getters.appointmentType==1 " class="select-group">
        <div class="flex">
          <van-field
            v-model="appointment1"
            is-link
            readonly
            name="calendar"
            label="選擇"
            placeholder="請選擇日期"
            @click="showCalendar1 = true"
          />
          <div @click="showPeriod1 = true">{{period1}}</div>
          <van-popup :show="showPeriod1" position="bottom">
            <van-picker
              :columns="periodList"
              @confirm="onSelectPeriod1"
              @cancel="showPeriod1 = false"
            />
          </van-popup>
          <van-calendar :show="showCalendar1" :min-date="minDate" :max-date="maxDate" color="#227CC8" @close="showCalendar1 = false" @confirm="onSelectOne" /> 
        </div>
       <div class="flex">
          <van-field
              v-model="appointment2"
              is-link
              readonly
              name="calendar"
              label="選擇(選填)"
              placeholder="請選擇日期"
              @click="showCalendar2 = true"
            />
          <div @click="showPeriod2 = true">{{period2}}</div>
          <van-popup :show="showPeriod2" position="bottom">
            <van-picker
              :columns="periodList"
              @confirm="onSelectPeriod2"
              @cancel="showPeriod1 = false"
            />
          </van-popup>
          <van-calendar :show="showCalendar2" :min-date="minDate" :max-date="maxDate" color="#227CC8" @close="showCalendar2 = false" @confirm="onSelectTwo" /> 
          </div>
          <div class="flex">
          <van-field
              v-model="appointment3"
              is-link
              readonly
              name="calendar"
              label="選擇(選填)"
              placeholder="請選擇日期"
              @click="showCalendar3 = true"
            />
             <div @click="showPeriod3 = true">{{period3}}</div>
          <van-popup :show="showPeriod3" position="bottom">
            <van-picker
              :columns="periodList"
              @confirm="onSelectPeriod3"
              @cancel="showPeriod3 = false"
            />
          </van-popup>
            <van-calendar :show="showCalendar3" :min-date="minDate" :max-date="maxDate" color="#227CC8" @close="showCalendar3 = false" @confirm="onSelectThr" /> 
        </div>
      </div>
      <div class="btn-group mt30">
        <van-button round type="primary"  native-type="submit" block>{{doctorModel.appointment_enabled == 0?'立即排隊':'立即預約'}}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import userApi from '@/api/api-user'
import Back from '@/components/back.vue'
import { useRouter } from "vue-router";
import store from '@/store'
import mitt from "mitt"
import { Dialog, Toast } from 'vant'
import { getToken } from '../utils/auth';
// import { computed } from '@vue/reactivity';

export default {
  name: 'history',
  components:{
    Back,
  },
  setup() {
    const router = useRouter();
    const emitter = mitt()
    const form = reactive({
      phone: store.getters.userInfo.phone.split("_")[1],
    });
    const doctorModel = ref({});
    let addressList = ref([]);
    let familyList = ref([]);
    const showCalendar1 = ref(false);
    const showCalendar2 = ref(false);
    const showCalendar3 = ref(false);
    const appointment1 = ref(new Date().format("yyyy-MM-dd"));
    const appointment2 = ref('');
    const appointment3 = ref('');
    const loading = ref(false);
    const finished = ref(false);
    const loadingAdd = ref(false);
    const finishedAdd = ref(false);
    const loadingFam = ref(false);
    const finishedFam = ref(false);
    const refreshing = ref(false);
    const isShowAddress = ref(false);
    const isShowFamily = ref(false);
    const periodList = ['AM', 'PM']
    const showPeriod1 = ref(false);
    const period1 = ref('AM');
    const showPeriod2 = ref(false);
    const period2 = ref('AM');
    const showPeriod3 = ref(false);
    const period3 = ref('AM');
    // const selected = ref(0);
    const addVal = ref({});
    const famVal = ref({})

    // eslint-disable-next-line no-unused-vars
    onMounted(() => {
      console.log('mounted in the composition api!')
      onLoad();
    })
    const onSelectPeriod1 = (data) => {
      showPeriod1.value = false;
      period1.value = data;
    };
    
    const onSelectPeriod2 = (data) => {
      showPeriod2.value = false;
      period2.value = data;
    };
    
    const onSelectPeriod3 = (data) => {
      showPeriod3.value = false;
      period3.value = data;
    };
    
    const onLoad = () => {
      // 异步更新数据
      getData();
    };
    const getData  = ()=> {
      let doctorId = router.currentRoute.value.query.doctorId
      let clinicId = router.currentRoute.value.query.clinicId
      userApi.getDoctorList({"page": 1,"doctor_id": doctorId, "clinic_id": clinicId}).then(res => {
        console.log(res)
        doctorModel.value = res.doctor_clinics[0]
        console.log(doctorModel)
        loading.value = false;
        refreshing.value = false;
      })  
    }
    const onSelectOne = (date) => {
      appointment1.value = new Date(date).format("yyyy-MM-dd");
      showCalendar1.value = false;
    };
    const onSelectTwo = (date) => {
      appointment2.value = new Date(date).format("yyyy-MM-dd");
      showCalendar2.value = false;
    };
    const onSelectThr = (date) => {
      appointment3.value = new Date(date).format("yyyy-MM-dd");
      showCalendar3.value = false;
    };

    const onSubmit = (values) => {
      if(!store.getters.family){
        Toast('請選擇家庭成員');
        return;
      }
      if(!values.phone){
        Toast('請輸入手機號碼');
        return;
      }
      const token = getToken();
      if(doctorModel.value.appointment_enabled == 1){
        let params = {
          clinic_id: doctorModel.value.clinic.id,
          doctor_id: doctorModel.value.doctor.id,
          user_id: store.getters.userInfo.id,
          contact_phone: "852_" + values.phone,
          visit_type: "IN_CLINIC",
          mylink_uid: store.getters.userInfo.mylink_uid,
        }
        if(store.getters.appointmentType == 1){
          params["desired_date"] = appointment1.value;
          params["desired_period"] = period1.value.toLowerCase();
          if(appointment2.value != ''){
            console.log(appointment2.value)
            params["desired_time_slots[0][date]"] = appointment2.value;
            params["desired_time_slots[0][period]"] = period2.value.toLowerCase();
          }
          if(appointment3.value != ''){
            params["desired_time_slots[1][date]"] = appointment3.value;
            params["desired_time_slots[1][period]"] = period3.value.toLowerCase();
          }
        }
          /**
           * clinic_id: 1, 
           * doctor_id: D12826, 
           * visit_type: IN_CLINIC, 
           * contact_phone: 852_93359870, 
           * desired_date: 2022-08-02, 
           * desired_period: am, 
           * desired_time_slots[0][date]: 2022-08-10, 
           * desired_time_slots[0][period]: pm, 
           * desired_time_slots[1][date]: 2022-08-11, 
           * desired_time_slots[1][period]: am
           */
        store.dispatch('appointment/appointment', params).then(res => {
          console.log(res)
          finished.value = true;
          if(store.getters.userInfo&&store.getters.userInfo.heals_uid){
            Dialog.alert({
              title: '重要提醒',
              message: '請使用醫聊通app完成預約',
              confirmButtonText: '確認',
              theme: 'round-button',
              'confirm-button-color': '#227CC8',
            }).then(() => {
              toDownload();
            });
          }  else {
            router.push({name: 'Login'})
          }
         
        })
      } else {
        store.dispatch('appointment/queue', {
          clinic_id: doctorModel.value.clinic.id,
          doctor_id: doctorModel.value.doctor.id,
          user_id: store.getters.family.id,
          // phone: "852_" + values.phone,
          is_video_visit: store.getters.appointmentType,
          delivery_method: 'HOME',
          delivery_service: 'STANDARD',
          delivery_address_id: !token ? null : addVal.value.id,
          mylink_uid: store.getters.userInfo.mylink_uid,
        }).then(res => {
          console.log(res)
          finished.value = true;
          if(store.getters.userInfo&&store.getters.userInfo.heals_uid){
            Dialog.alert({
              title: '重要提醒',
              message: '請使用醫聊通app完成預約',
              confirmButtonText: '確認',
              theme: 'round-button',
              'confirm-button-color': '#227CC8',
            }).then(() => {
              toDownload();
            });
          }  else {
            router.push({name: 'Login'})
          }
         
        })
      }
    };
    const handleItem=(data)=>{
      store.commit('appointment/SET_APPOINTMENT_TYPE', data)

    }
  
    const showAddress=()=>{
      router.push({
        name: "AddressList",
      });
    }
  
    const showFamily=()=>{
      router.push({
        name: "FamilyList",
      });
    }
    const onClick =()=>{
      router.back();
    }
 
    const handleAddItemClick=(item)=>{
      addVal.value = item;
    }
    const handleFamItemClick=(item)=>{
      famVal.value = item;
      // isShowFamily.value = false;
    }
    const goBack =()=>{
      router.back();
    }
    const toDownload=()=>{
      router.push({
        name: "Download",
      });
    }
    return {
      store,
      router,
      onLoad,
      getData,
      goBack,
      handleItem,
      onClick,
      showFamily,
      handleAddItemClick,
      handleFamItemClick,
      onSubmit,
      toDownload,
      showAddress,
      onSelectPeriod1,
      onSelectPeriod2,
      onSelectPeriod3,
      periodList,
      showPeriod1,
      period1,
      showPeriod2,
      period2,
      showPeriod3,
      period3,
      emitter,
      form,
      familyList,
      addressList,
      addVal,
      famVal,
      doctorModel,
      // selected,
      finished,
      loading,
      loadingAdd,
      loadingFam,
      finishedAdd,
      finishedFam,
      refreshing,
      isShowAddress,
      isShowFamily,
      appointment1,
      appointment2,
      appointment3,
      showCalendar1,
      showCalendar2,
      showCalendar3,
      onSelectOne,
      onSelectTwo,
      onSelectThr
    }
  }
}
</script>

<style scoped>
#detail{
  position: relative;
}
.top-wapper{
  position: relative;
}
.doctor-bg{
  width: 100%;
  height: 22.5rem;
}
.top-box{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
}
.avatar{
  width: 70px;
  height: 70px;
  margin-top: 70px;
}
.info-box{
  width: calc(100% - 32px);
  margin: .4rem auto;
  background: rgba(255,255,255,0.1000);
  box-shadow: 0px 7px 21px 0px rgba(70,153,140,0.1700);
  border-radius: 12px;
  padding: .4rem;
  text-align: left;
  margin-top: .8rem;
}
.info-icon{
  width: .8rem;
  height: .8rem;
  margin-right: .8rem;
}
.info-text{
  text-align: left;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
}
.wait-box {
  width: calc(100% - 64px);
  height: 2.7rem;
  margin: .8rem  auto;
  background: #FFFFFF;
  box-shadow: 0px 7px 21px 0px rgba(70,125,153,0.1700);
  border-radius: 12px;
  padding: 0 .8rem;
}
.wait-title{
  text-align: left;
  font-size: .8rem
}
.select-wapper{
  padding: 0  1.6rem
}
.select-item{
  width: 48%;
  height: 1.5rem;
  background: #E6F4FF;
  border: 1px solid #4CADFF;
  border-radius: 29px;
}
.select-item.active{
  background: #227CC8;
  border-radius: 29px;
}
.select-icon{
  width: 1rem;
  object-fit: cover;
  margin-right: .4rem;
}
.select-title{
  color: #227CC8;
  font-size: .8rem;
}
.select-item.active .select-title{
  color: #fff;
}
.select-group-normal{
  padding: .6rem .8rem;
  margin: .2rem 0;
}
.select-title{
  text-align: left;
}
.icon-arrow{
  width: .4rem;
  object-fit: cover;
  vertical-align: middle;
}
.input-label{
  text-align: left;
  margin-left: .8rem;
}
.van-cell{
  padding-right: 0;
  padding-left: 0;
}
.input-group{
  text-align: right;
  font-size: .8rem;
}
.btn-group{
  padding: .8rem;
}
.van-cell__group{
  margin: 1.2rem;
  box-sizing: border-box;
}
.list-wapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.avatar-icon{
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid #eee;
  margin-right: .8rem
}
.select-group{
  padding: 0 1.6rem;
}
:deep().van-field__control:read-only {
  text-align: right
}
:deep().van-field__label{
    color: #227CC8;
    font-size: .8rem;
    width: 100px;
  }
.mt30 {
  margin-top: 1.5rem;
}
:deep().van-icon-arrow:before {
  content: '';
}
</style>