<template>
  <div>
    <div class=" main-shadow">
      <back/>
    </div>
    <div  class="download">
      <img src="../assets/kv-banner-tc.png" alt="" class="top-bg">
      <div class="download-middle">
        <div class="download-card">
          <div class="card-content">
            <h2 class="card-title">下一步</h2>
            <div>
              <p class="card-first">1、下載<label for="">"醫聊通DR.HK"</label></p>
              <p class="card-second">2、登陸<label for="">"醫聊通DR.HK"</label>賬號</p>
            </div>
          </div>
          <img src="../assets/card-bg.png" alt="" class="card-bg">
        </div>
        <div class="download-group flex">
          <div class="download-item">
            <img src="../assets/code1.png" alt="" class="code-logo">
            <img src="../assets/appstore.png" alt="" class="download-icon mr64" @click="onClick('https://apps.apple.com/cn/app/id1616454814')">
          </div>
          <div style="width: 32px"></div>
          <div class="download-item">
            <img src="../assets/code2.png" alt="" class="code-qr">
            <img src="../assets/google-play.png" alt="" class="download-icon"  @click="onClick('https://play.google.com/store/apps/details?id=com.heals.cmhkapp')">
          </div>
        </div>
      </div>
      <div class="download-footer">
        <ul class="download-ul">
          <li>醫療網絡突破界限*</li>
          <li>涵蓋西醫、中醫及多個專科</li>
          <li>線上預約多渠道看診</li>
          <li>隨時手機取籌，不再等待 </li>
          <li>即日送藥上門</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref  } from 'vue';
import Back from '@/components/back.vue'
export default {
  name: 'download',
  components:{
    Back
  },
  setup() {
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onClick = (url)=> {
      window.location.href  = url
    }
    return {
      onClick,
      finished,
      loading,
      refreshing
    }
  }
}
</script>
<style scoped>
  .download {
    background: linear-gradient(#fff, #97dbf5);
    /* border-radius: 40px; */
    /* margin-top: 1.8rem; */
    /* padding: 1.5rem 1rem; */
    color: #fff;
    text-align: left;
    min-height: 100vh;
    /* margin-left: 1.2rem;
    margin-right: 1.2rem; */
  }
  .top-bg{
    width: 100%;
  }
  .card-bg{
    position: absolute;
    width: 380px;
    margin: 0 auto;
    height: 180px;
    top: 0;
    left: 0;
    right: 0
  }
  .download-middle{
    position: relative;
    margin-top: -52px;
    padding-bottom: 2.5rem;
    /* background: linear-gradient(#fff, #97dbf5); */
  }
  .download-card{
    position: relative;
  }
  .download-card .card-box{
    position: absolute;
    width: 100%;
  }
  .download-card .card-content{
    position: relative;
    z-index: 100;
    color: #227CC8;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;

  }
  .download-card .card-content .card-title{
    color: #fff;
    font-size: 24px;
    padding-right: 220px;
    margin-bottom: 4px;
  }
  .download-card .card-content p{
    color: #227CC8;
    margin-top: 6px;
    margin-bottom: 0;
  }
  .download-card .card-content .card-second{
    margin-bottom: 16px;
    color: #227CC8;
    margin-top: 12px;
  }
  .download-card .card-content p label{
    color: #a1c269;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .download-group{
    padding-top: .8rem;
  }
  .download-group .download-item{
    flex-direction: column;
    display: flex;
    text-align: center;
    align-items: center;
  }
  .code-logo{
    width: 8rem;
    object-fit: cover;
  }
  .code-qr{
    width: 8rem;
    object-fit: cover;
  }
  .app-logo{
    width: 4rem;
    height: 4rem;
  }
  .download h3{
    margin: 0 .8rem .8rem 0;
    vertical-align:text-top;
  }
  .download .title{
    font-weight: bold;
    font-size: 1.4rem
  }
  .download .down-desc{
    font-size: 1rem;
    line-height: 2rem;
  }
  .download-icon{
    height: 2rem;
    margin-top: .8rem;
  }
  .download-footer{
    background: url('../assets/bottom-bg-1.png') no-repeat;
    background-size: 100% 120px;
    height: 120px;
  }
  .download-footer .download-ul{
    padding-top: 2.6rem;
  }
  .download-footer .download-ul li{
    font-size: .8rem;
    font-weight: bold;
    width: 50%;
    float:left;
    box-sizing: border-box;
    padding-left: 1.6rem;
    line-height: 1.2rem;
  }
  .mr64{
   margin-right: 1.6rem
  }
</style>
