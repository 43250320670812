<template>
  <div id="history">
    <div class="header main-shadow">
      <back/>
      <h3> Dr.HK | 醫聊通 </h3>
    </div>
    <iframe
      ref="iframeRef"
      :src="statementUrl"
      style="width: 100vw; height: 100vh;"
      scrolling="auto"
      frameborder="0"
      id="iframe"
    >
    </iframe>
  </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import Back from '@/components/back.vue'
import { useRouter } from "vue-router";
export default {
  name: 'history',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const statementUrl = ref('');
    onMounted(() => {
      statementUrl.value = router.currentRoute.value.params.statementUrl;
      console.log('mounted in the composition api!', statementUrl.value)
    })
    const goBack =()=>{
      router.back();
    }
    return {
      router,
      goBack,
      statementUrl
    }
  }
}
</script>
