<template>
  <div id="history">
    <div class="header main-shadow">
      <back/>
      <h3> Dr.HK | 醫聊通 </h3>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="No more"
      error-text="請求失敗，點擊重新加載"
      @load="onLoad"
    >
      <van-cell v-for="(item, i) in historyList" :key="i">
        <template v-slot:title>
          <div class="van-cell__group">
            <div class="row">
                <img src="../assets/medicine_box.png" alt="" class="box-icon">
                <div class="flex1 text-align-left active-text">
                  <span class="flex1 appointment-title">{{item.doctors.name}}</span>
                </div>
              </div>
              <div class="ml30 text-align-left">
                <span class="text-align-left">{{item.user.nickname}}</span>
              </div>
              <div class="row ml30 borderB appointment-subtitle">
                <span class="text-align-left">{{item.clinic.name}}</span>
              </div>
              
              <div >
                <div class="appointment-txt">診症：<label v-for="(dia, j) in item.diagnosis" :key="j">{{dia.name}}</label></div>
              </div>
              <div v-for="(des, j) in item.prescriptions"  :key="j">
                <div class="appointment-txt">藥物名稱：{{des.name}}</div>
                <div class="appointment-txt">用法：{{des.usage}}</div>
                <div class="appointment-txt">開始日期：{{des.start_date}}</div>
                <div class="appointment-txt">次數：{{des.frequency}}</div>
                <div class="appointment-txt">日數：{{des.duration}}</div>
                <div class="appointment-txt">數量：{{des.dosage}}</div>
              </div>
          </div>
        </template>
      </van-cell>
    </van-list>
    <van-empty v-if="!loading&&historyList.length==0" description="沒有就診記錄" />
  </div>
</template>

<script>
import { ref  } from 'vue';
import userApi from '@/api/api-user'
import Back from '@/components/back.vue'
import { useRouter } from "vue-router";
export default {
  name: 'history',
  components:{
    Back
  },
  setup() {
    const router = useRouter();
    const historyList = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    
    const onLoad = () => {
      // 异步更新数据
      getData();
    };
    const getData  = ()=> {
      console.log('historyList')
      userApi.getHistories().then(res => {
        historyList.value = res.medical_histories;
        console.log(historyList)
        loading.value = false;
        refreshing.value = false;
      })  
    }
    const goBack =()=>{
      router.back();
    }
    return {
      router,
      onLoad,
      goBack,
      historyList,
      finished,
      loading,
      refreshing
    }
  }
}
</script>

<style scoped>
.icon-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3.5rem;
    line-height: 4rem;
    padding-right: 1.2rem;
    background: url(../assets/health_passport.png) no-repeat;
    background-size: 1.4rem;
    background-position-y: 1rem;
  }
  /* .van-list {
    flex: 1;
    height: calc(100% - 1rem);
    overflow-y: scroll;
  } */
  .van-dropdown-menu {
    margin-bottom: .8rem;
  }
  .van-cell:after{
    border-bottom: none;
  }
  .van-cell__group .row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
  }
  .van-cell__group .box-icon{
    width: 1.1rem;
    margin-right: .4rem;
  }
  .van-cell__group .like-icon{
    width: 1.1rem;
    margin-right: .4rem;
  }
  .appointment-title {
    font-size: .9rem;
    font-weight: bold;
  }
  .appointment-subtitle {
    padding-bottom: .7rem;
    margin-top: .8rem;
  }
  .appointment-mark {
    margin-top: .2rem;
  }
  .appointment-mark label{
    font-size: 1rem;
    font-weight: bold;
    margin-left: .25rem;
  }
  .appointment-txt{
    text-align: left;
    margin-left: 1.5rem;
  }
  .van-empty{
    margin-top: 5rem
  }
</style>